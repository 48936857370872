import { Auth } from 'aws-amplify';
import { getDomain } from './constants';
import { MODULES_PATH } from './route-constants';

export const USER_API_GATEWAY_ENDPOINT_NAME = 'User-API-Gateway-Endpoint';
export const LEARN_SUBMISSION_API_ENDPOINT_NAME = 'LEARN_SUBMISSION_API_ENDPOINT_NAME';
export const REFINER_API = 'REFINER_API';
export const REFERRAL_RUPEES_TO_COINS = 10;

export const LEADSQUARED_API_GATEWAY_ENDPOINT_NAME = 'LEADSQUARED_API_ENDPOINT_NAME';

export const USER_API_GATEWAY_ENDPOINT =
  process.env.REACT_APP_USER_API_GATEWAY_ENDPOINT ||
  'https://c3yd5bh60c.execute-api.ap-south-1.amazonaws.com';
const LEARN_SUBMISSION_API_ENDPOINT =
  process.env.REACT_APP_COURSE_LMS_API_GATEWAY_ENDPOINT ||
  'https://6c7jn568a0.execute-api.ap-south-1.amazonaws.com';
const LEADSQUARED_API_GATEWAY_ENDPOINT =
  process.env.REACT_APP_LEADSQUARED_API_ENDPOINT ||
  'https://62hfbkofzc.execute-api.ap-south-1.amazonaws.com';

export const REFINER_API_KEY = 'b1bf343e-cdb5-4168-acf8-2d31571f4f8d';

const DOMAIN = getDomain() || 'http://localhost:3000';
const AWS_REGION = process.env.REACT_APP_AWS_REGION || 'ap-south-1';
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || 'ap-south-1_2xDr5HmfJ';
const USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '715oetv9o2f4jugf6to7u87r0r';
const USER_POOL_OAUTH_DOMAIN =
  process.env.REACT_APP_USER_POOL_OAUTH_DOMAIN ||
  'id-dev-almabetter.auth.ap-south-1.amazoncognito.com';
const ENV = process.env.REACT_APP_ENV || 'dev';
const IDENTITY_POOL_ID =
  process.env.REACT_APP_IDENTITY_POOL_ID || 'ap-south-1:5ef56f79-cca8-4903-a118-94aa6caab07b';
const config = {
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: USER_POOL_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${DOMAIN}${MODULES_PATH.AUTH.LOGIN_SUCCESSFUL}/`,
      redirectSignOut: `${DOMAIN}${MODULES_PATH.AUTH.LOGOUT_SUCCESSFUL}/`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: USER_API_GATEWAY_ENDPOINT_NAME,
        endpoint: USER_API_GATEWAY_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
      {
        name: LEARN_SUBMISSION_API_ENDPOINT_NAME,
        endpoint: LEARN_SUBMISSION_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
      {
        name: REFINER_API,
        endpoint: 'https://api.refiner.io/v1',
        custom_header: async () => {
          return {
            Authorization: `Bearer ${REFINER_API_KEY}`,
          };
        },
      },
      {
        name: LEADSQUARED_API_GATEWAY_ENDPOINT_NAME,
        endpoint: LEADSQUARED_API_GATEWAY_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: `ab-user-files-${ENV}`,
      region: AWS_REGION,
    },
  },
};

export default config;
