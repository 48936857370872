import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useEffect, Suspense, lazy } from 'react';
import Layout from './Layout';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { MODULES_PATH } from './route-constants';
import AuthRouter from './pages/auth/router';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { switchTheme } from './redux/colorModeSlice';
import { giveFeedback } from './services/refinerApi';
import { LoadingAlmabetterDesign } from './modules/course-lms-v2/components/shared/components/Loader';
import { courseSelectedSelector } from './redux/courseSlice';
import { ToastContainer } from 'react-toastify';
import { COURSE_TYPE } from './constants';
import WoolfProvider from './services/woolfUninversity/woolfProvider';
import WebSocketProvider from './plugins/webhook/webSocketProvider';
import './global.css';

// create the lazy components using React.lazy and the import functions
const Community = lazy(() => import('./modules/community/Community'));
const LoggedInApp = lazy(() => import('./pages/LoggedInApp'));

function App() {
  const dispatch = useDispatch();
  const colorTheme = useSelector((state) => state.colorModeReducer.colorTheme);
  const courseSelected = useSelector(courseSelectedSelector);
  const { hideScrollbar } = useSelector((state) => state.homeReducer);
  const appliedTheme = createTheme(colorTheme);

  useEffect(() => {
    const course = window.location.href.split('/')?.[3];
    const courseList = [
      COURSE_TYPE.DATA_SCIENCE,
      COURSE_TYPE.DATA_ENGINEERING,
      COURSE_TYPE.MASTERS_DS_AS,
      COURSE_TYPE.MASTERS_CS,
      COURSE_TYPE.WEB_DEV,
      COURSE_TYPE.DS_BOOTCAMP,
    ];
    const courseCondition = courseList?.includes(course);
    if (courseCondition) {
      localStorage.setItem('courseSelected', course);
    }
    giveFeedback();
    localStorage.setItem('color-theme', 'light');
    dispatch(switchTheme('light'));
    return () => clearInterval(interval);
  }, []);

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID || '', null, options);

  if (process.env.REACT_APP_EMERGENCY_MESSAGE) {
    return <div>{process.env.REACT_APP_EMERGENCY_MESSAGE}</div>;
  }

  return (
    <div
      className={`${
        hideScrollbar ? 'no-scrollbar' : ''
      } w-full min-h-screen max-h-screen font-montserrat bg-ab-tertiary dark:bg-ab-primary text-ab-primary-shade-1 dark:text-ab-tertiary relative overflow-x-hidden`}
    >
      <WoolfProvider>
        <WebSocketProvider>
          <Suspense fallback={<LoadingAlmabetterDesign />}>
            <Router>
              <Layout>
                <ThemeProvider theme={appliedTheme}>
                  <Switch>
                    <Route path={MODULES_PATH.AUTH.HOME} component={AuthRouter} />
                    <Route path={MODULES_PATH.COMMUNITY_LANDING} component={Community} />
                    <Route path='/' component={LoggedInApp} />
                  </Switch>
                </ThemeProvider>
              </Layout>
            </Router>
          </Suspense>
        </WebSocketProvider>
        <div className='whitespace-pre-line'>
          <ToastContainer />
        </div>
      </WoolfProvider>
    </div>
  );
}

export default App;
