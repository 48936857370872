import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MODULES_PATH } from '../../../route-constants';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import LeftSignupContent from '../_components/leftContent';
import { GoogleIcon } from '../_assets/logos';
import { captureParams, getRedirectTo } from 'src/utils';
import { trackPageView } from '../../../services/mixpanel';

function storeRedirectTo() {
  const redirectTo = getRedirectTo();
  const queryString = captureParams();

  if (redirectTo) {
    const redirectToValue = queryString ? redirectTo + queryString : redirectTo;

    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_TO, redirectToValue);
    return;
  }

  if (queryString) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CAMPAIGN_PARAMS, queryString);
  }
}

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  const [disable, setDisable] = useState(false);

  const ifUserIsAuthenticatedRedirect = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        history.push(MODULES_PATH.AUTH.LOGIN_SUCCESSFUL);
      }
    } catch (err) {}
  };

  useEffect(() => {
    storeRedirectTo();
    ifUserIsAuthenticatedRedirect();
    trackPageView({ page: 'Login' });
  }, []);

  async function signIn() {
    setDisable(true);
    try {
      const lowercaseEmail = email.toLowerCase();
      await Auth.signIn(lowercaseEmail, password);
      await ifUserIsAuthenticatedRedirect();
    } catch (error) {
      setError(error);
    } finally {
      setDisable(false);
    }
  }

  const validate = (e) => {
    e.preventDefault();
    const temp = {};
    if (email === '') {
      temp.email = 'This field is required';
    }
    if (password === '') {
      temp.password = 'This field is required';
    }

    if (Object.keys(temp)?.length) setError(temp);
    else signIn();
  };

  return (
    <div className='w-full min-h-screen grid md:grid-cols-12 md:grid-rows-1 grid-rows-12'>
      <LeftSignupContent type={true} />
      <div className='md:col-span-7 text-center   md:py-16 py-10 '>
        <div className='flex flex-col gap-8 justify-center col-span-12 h-full md:w-auto w-screen'>
          <div className='flex flex-col gap-2 md:px-2 px-4'>
            <h1 className='sm:text-4xl custom-400:text-3xl text-2xl'>Sign in to AlmaBetter✨</h1>
            <p className='md:text-xl text-base'>
              Build a better career in tech with guaranteed outcome!
            </p>
          </div>
          <div className='flex justify-center'>
            <div
              className='rounded-sm p-4 text-white flex items-center justify-center gap-6 cursor-pointer'
              style={{
                backgroundColor: '#3F7FDF',
              }}
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                })
              }
            >
              <div className='bg-white rounded-sm w-8 '>
                <GoogleIcon />
              </div>
              <p className='custom-400:text-xl text-lg'>Continue with Gmail</p>
            </div>
          </div>

          <p>or</p>
          <div className='flex justify-center flex-col items-center'>
            <div className='lg:w-1/2 md:w-7/12 sm:w-8/12 custom-400:w-9/12 w-10/12'>
              <form onSubmit={validate} className='flex flex-col text-left'>
                <div className='flex flex-col gap-y-3'>
                  {error.message && (
                    <span className='text-sm text-red-500 flex items-center gap-1'>
                      <ErrorOutlineIcon /> {error.message}
                    </span>
                  )}
                  <div className='flex flex-col'>
                    <label htmlFor='email'>Your Email</label>
                    <input
                      className='border border-solid border-gray-300 rounded h-12 p-2'
                      type='text'
                      id='email'
                      placeholder='Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
                    />
                    {email === '' && <span className='text-sm text-red-500'>{error.email}</span>}
                  </div>
                  <div className='flex flex-col'>
                    <label htmlFor='password'>Your Password</label>
                    <input
                      className='border border-solid border-gray-300 rounded h-12 p-2'
                      type='password'
                      id='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {password === '' && (
                      <span className='text-sm text-red-500'>{error.password}</span>
                    )}
                  </div>
                </div>
                <Link
                  to={MODULES_PATH.AUTH.FORGOT_YOUR_PASSWORD}
                  className='ml-auto text-sm text-learn-primary pt-2'
                >
                  Forgot Password?
                </Link>
                <button
                  disabled={disable}
                  className='bg-learn-primary text-ab-tertiary w-full py-4 rounded my-4 font-medium mt-12'
                  type='submit'
                >
                  {disable ? (
                    <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
                  ) : (
                    'Sign In'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className='md:flex justify-center items-end hidden'>
          <div className='flex justify-center items-center gap-2'>
            <span>Don&apos;t have an account ?</span>
            <span
              className='text-learn-primary cursor-pointer border border-solid border-learn-primary px-4 py-1 rounded-sm'
              onClick={() => {
                const redirectUrl = getRedirectTo();
                if (redirectUrl) {
                  history.replace(`/auth/signup?redirectTo=${redirectUrl}`);
                } else {
                  history.replace(`/auth/signup`);
                }
              }}
            >
              Sign Up
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
