import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MODULES_PATH } from '../../../route-constants';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Auth } from 'aws-amplify';
import { captureParams, getRedirectTo, validateEmail, validateFullname } from '../../../utils';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CircularProgress } from '@material-ui/core';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { GoogleIcon } from '../_assets/logos';
import LeftSignupContent from '../_components/leftContent';
import { trackPageView } from '../../../services/mixpanel';

function getReferralCode() {
  const urlSearchText = window.location.search;
  if (urlSearchText) {
    const urlSearchParams = new URLSearchParams(urlSearchText);
    const referralCode = urlSearchParams.get('referralCode');
    const utmSource = urlSearchParams.get('utm_source');
    if (!referralCode) return {};
    return { referralCode, utmSource };
  }
}

function getUserCourseInterests() {
  const interests = [];
  const urlSearchText = window.location.search;
  if (urlSearchText) {
    const urlSearchParams = new URLSearchParams(urlSearchText);
    urlSearchParams.forEach((queryParamVal, queryParam) => {
      if (queryParam.endsWith('Interest')) interests.push(queryParam);
    });
  }
  return interests;
}

function storeRedirectToAndUserCourseInterests() {
  const redirectTo = getRedirectTo();

  const queryString = captureParams();

  const { referralCode, utmSource } = getReferralCode() ?? {};
  const urlSearchText = window.location.search;
  const urlSearchParams = new URLSearchParams(urlSearchText);
  const refferalGoal = urlSearchParams.get('goal');
  const refferalPlan = urlSearchParams.get('plan');

  if (referralCode) {
    refferalGoal && localStorage.setItem(LOCAL_STORAGE_KEYS.REFERRAL_GOAL, refferalGoal);
    refferalPlan && localStorage.setItem(LOCAL_STORAGE_KEYS.REFERRAL_PLAN, refferalPlan);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFERRAL_CODE, referralCode);
  }

  if (utmSource && ['referearn', 'shareearn']?.includes(utmSource)) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFERRAL_SOURCE, utmSource);
  }

  const interests = getUserCourseInterests();

  if (interests?.length) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.COURSE_INTERESTS, JSON.stringify(interests));
  }

  if (redirectTo) {
    const redirectToValue = queryString ? redirectTo + queryString : redirectTo;

    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_TO, redirectToValue);
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_TO, redirectToValue);
    return;
  }

  if (queryString) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CAMPAIGN_PARAMS, queryString);
  }
}

export default function SignUp() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [userName, setUserName] = useState('');
  const ifUserIsAuthenticatedRedirect = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        history.push(MODULES_PATH.AUTH.LOGIN_SUCCESSFUL);
      }
    } catch (err) {
      if (source && source === 'rcb_success') {
        (async () => {
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          });
        })();
      }
    }
  };
  useEffect(() => {
    storeRedirectToAndUserCourseInterests();
    ifUserIsAuthenticatedRedirect();
    trackPageView({ page: 'Signup' });
  }, []);

  async function signUp() {
    setDisable(true);
    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          name: userName?.replace(/\s+/g, ' ').trim(),
        },
      });
      await Auth.signIn(email.toLowerCase(), password);
      await ifUserIsAuthenticatedRedirect();
    } catch (error) {
      setError(error);
    }
    setDisable(false);
  }

  const validate = () => {
    const temp = {};
    temp.email = email
      ? validateEmail(email)
        ? ''
        : 'Please enter a valid email'
      : 'This field is required';
    temp.userName = userName
      ? validateFullname(userName)
        ? ''
        : 'Please enter your full name'
      : 'This field is required';
    temp.password = password ? (error.password ? error.password : '') : 'This field is required';

    if (!Object.values(temp).every((value) => value === '')) setError(temp);
    else signUp();
  };

  const validatePassword = (score, feedback) => {
    if (password && score >= 0 && score <= 2) {
      setError({
        ...error,
        password: feedback.warning ? feedback.warning : 'Please select a better password',
      });
    } else setError({ ...error, password: '' });
  };

  const googleSignup = () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='w-full min-h-screen grid md:grid-cols-12 md:grid-rows-1 grid-rows-12'>
      <LeftSignupContent type={false} />
      <div className='md:col-span-7 text-center col-span-12 py-10 md:py-16'>
        <div
          className={`h-full flex items-center flex-col justify-center gap-8  ${
            !clicked ? 'justify-start md:justify-center' : 'justify-center'
          }`}
        >
          <div className='flex flex-col gap-2 md:px-2 px-4'>
            <h1 className='sm:text-4xl custom-400:text-3xl text-2xl'>Welcome to AlmaBetter✨</h1>
            <p className='md:text-xl text-base'>
              Sign up today to kickstart your learning and land your dream job!
            </p>
          </div>
          <div className='flex justify-center'>
            <div
              className='rounded-sm p-4 text-white flex items-center justify-center gap-6 cursor-pointer'
              style={{
                backgroundColor: '#3F7FDF',
              }}
              onClick={googleSignup}
            >
              <div className='bg-white rounded-sm w-8'>
                <GoogleIcon />
              </div>
              <p className='custom-400:text-xl text-lg'>Continue with Gmail</p>
            </div>
          </div>
          {!clicked && (
            <p
              style={{ color: 'rgba(35, 35, 35, 0.8)' }}
              className='underline cursor-pointer'
              onClick={() => {
                setClicked(true);
              }}
            >
              Don&apos;t have Gmail? Sign up with Email
            </p>
          )}
          {clicked ? (
            <>
              <p>or</p>
              <div className='flex justify-center flex-col items-center'>
                <div className='w-10/12'>
                  <div className='flex flex-col gap-y-3 '>
                    {error.message && (
                      <span className='text-sm text-red-500 flex items-center gap-1'>
                        <ErrorOutlineIcon /> {error.message}
                      </span>
                    )}
                    <div className='flex flex-col text-left'>
                      <label htmlFor='userName'>
                        Full name (This name appears on your certificates)
                      </label>
                      <input
                        className='border border-solid border-gray-300 rounded h-12 p-2'
                        type='text'
                        id='userName'
                        placeholder='Write your full name'
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <span className='text-sm text-red-500'>{error.userName}</span>
                    </div>
                    <div className='flex flex-col text-left'>
                      <label htmlFor='email'>Your Email</label>
                      <input
                        className='border border-solid border-gray-300 rounded h-12 p-2'
                        type='text'
                        id='email'
                        placeholder='Write your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
                      />
                      <span className='text-sm text-red-500'>{error.email}</span>
                    </div>

                    <div className='flex flex-col text-left'>
                      <label htmlFor='password'>Choose Password</label>
                      <input
                        className='border border-solid border-gray-300 rounded h-12 p-2'
                        type='password'
                        id='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <PasswordStrengthBar password={password} onChangeScore={validatePassword} />
                      <span className='text-sm text-red-500'>{error.password}</span>
                    </div>
                  </div>
                  <div className='flex justify-center w-full'>
                    <button
                      className='bg-learn-primary text-ab-tertiary py-4 rounded my-4 font-medium w-full'
                      onClick={validate}
                    >
                      {disable ? (
                        <CircularProgress
                          style={{ width: '1rem', height: '1rem', color: '#fff' }}
                        />
                      ) : (
                        'Sign Up for free'
                      )}
                    </button>
                  </div>
                  <div className='flex justify-center'>
                    <h3 className='text-sm text-center' style={{ color: '#606060' }}>
                      By signing up, I accept AlmaBetter&apos;s{' '}
                      <span
                        className='text-learn-tertiary-shade-1 underline cursor-pointer'
                        onClick={() =>
                          window.open(
                            'https://www.almabetter.com/terms-of-use?_gl=1*mgksvj*_ga*MTc5MTI5NjUyMy4xNjc1NzYxNjEy*_ga_0J0G37K0RG*MTY4MzM1NzI5NC4zNTUuMS4xNjgzMzU4MjAxLjU5LjAuMA',
                            '_blank'
                          )
                        }
                      >
                        Terms of Services
                      </span>{' '}
                      and acknowledge the
                      <span
                        className='text-learn-tertiary-shade-1 cursor-pointer'
                        onClick={() =>
                          window.open(
                            'https://www.almabetter.com/privacy-policy?_gl=1*1d9pjny*_ga*MTc5MTI5NjUyMy4xNjc1NzYxNjEy*_ga_0J0G37K0RG*MTY4MzM1NzI5NC4zNTUuMS4xNjgzMzU4MjQ2LjU5LjAuMA',
                            '_blank'
                          )
                        }
                      >
                        {' '}
                        Privacy Policy
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='flex justify-center w-full'>
              <h3
                className='text-sm text-center md:w-1/2 custom-400:w-8/12 w-10/12'
                style={{ color: '#606060' }}
              >
                By signing up, I accept AlmaBetter&apos;s{' '}
                <span
                  className='text-learn-tertiary-shade-1 underline cursor-pointer'
                  onClick={() =>
                    window.open(
                      'https://www.almabetter.com/terms-of-use?_gl=1*mgksvj*_ga*MTc5MTI5NjUyMy4xNjc1NzYxNjEy*_ga_0J0G37K0RG*MTY4MzM1NzI5NC4zNTUuMS4xNjgzMzU4MjAxLjU5LjAuMA',
                      '_blank'
                    )
                  }
                >
                  Terms of Services
                </span>{' '}
                and acknowledge the
                <span
                  className='text-learn-tertiary-shade-1 cursor-pointer'
                  onClick={() =>
                    window.open(
                      'https://www.almabetter.com/privacy-policy?_gl=1*1d9pjny*_ga*MTc5MTI5NjUyMy4xNjc1NzYxNjEy*_ga_0J0G37K0RG*MTY4MzM1NzI5NC4zNTUuMS4xNjgzMzU4MjQ2LjU5LjAuMA',
                      '_blank'
                    )
                  }
                >
                  {' '}
                  Privacy Policy
                </span>
              </h3>
            </div>
          )}
        </div>
        <div className='md:flex justify-center gap-2 items-center hidden'>
          <span>Already have an account ?</span>
          <span
            className='text-learn-primary cursor-pointer border border-solid border-learn-primary px-4 py-1 rounded-sm'
            onClick={() => {
              const redirectUrl = getRedirectTo();
              if (redirectUrl) {
                history.replace(`/auth/login?redirectTo=${redirectUrl}`);
              } else {
                history.replace(`/auth/login`);
              }
            }}
          >
            Sign In
          </span>
        </div>
      </div>
    </div>
  );
}
