import { createSlice } from '@reduxjs/toolkit';
import {
  returnRespectiveMastersCourse,
  returnActualCourseNameForApi,
} from 'src/services/getcourse';
import { getMastersApplicationStatus, getMastersDegreeId } from 'src/services/mastersApiService';

const initialState = {
  loadOnboarding: false,
  currentPage: null,
  continueOnboarding: false,
  woolfInstance: null,
  masterStatus: 'START_APPLICATION',
  degreeId: {},
};

const mastersCourseSlice = createSlice({
  name: 'mastersReducer',
  initialState,
  reducers: {
    setLoadOnboarding(state, action) {
      state.loadOnboarding = action.payload;
    },
    setContinueOnboarding(state, action) {
      state.continueOnboarding = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setWoolfInstance(state, action) {
      state.woolfInstance = action.payload;
    },
    setMastersStatus(state, action) {
      state.masterStatus = action.payload;
    },
    setDegreeId(state, action) {
      state.degreeId = action.payload;
    },
  },
});

export const {
  setLoadOnboarding,
  setCurrentPage,
  setContinueOnboarding,
  setWoolfInstance,
  setMastersStatus,
  setDegreeId,
} = mastersCourseSlice.actions;

export const loadMastersOnboarding = (state) => state.mastersReducer.loadOnboarding;
export const currentMastersState = (state) => state.mastersReducer.currentPage;
export const continueMastersOnboarding = (state) => state.mastersReducer.continueOnboarding;
export const woolfInstanceSelector = (state) => state.mastersReducer.woolfInstance;
export const mastersStatusSelector = (state) => state.mastersReducer.masterStatus;
export const mastersDegreeIdSelector = (state) => state.mastersReducer.degreeId;

function pageSetter(status) {
  switch (status) {
    case 'init':
      return 'CONTINUE_APPLICATION';
    case 'profile_completed':
      return 'APPLICATION_PENDING';
    case 'ineligible':
      return 'INELIGIBLE';
    case 'seat_reserved':
      return 'SEAT_RESERVED';
    case 'application_verified':
      return 'APPLICATION_VERIFIED';
    case 'application_rejected':
      return 'APPLICATION_REJECTED';
    case 'payment_completed':
      return '';
    case 'added_to_degree':
      return 'PAYMENT_COMPLETED';
    case 'university_onboarded':
      return '';
    default:
      return 'START_APPLICATION';
  }
}
export const getMastersStatus = () => {
  return async (dispatch, getState) => {
    try {
      const actualCourseSelected = returnRespectiveMastersCourse(
        getState().courseReducer.courseSelected
      );
      const res = await getMastersApplicationStatus(actualCourseSelected);
      const status = pageSetter(res?.status);
      dispatch(setMastersStatus(status));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchMastersDegreeId = () => {
  return async (dispatch, getState) => {
    try {
      const actualCourseSelected = returnActualCourseNameForApi(
        getState().courseReducer.courseSelected
      );
      const res = await getMastersDegreeId(actualCourseSelected);

      dispatch(setDegreeId({ [actualCourseSelected]: res?.degreeId }));
    } catch (error) {
      console.error(error);
    }
  };
};

export default mastersCourseSlice.reducer;
