import { createSlice } from '@reduxjs/toolkit';
import {
  courseProgressData,
  lessonCompletedData,
  moduleProgressData,
  projectProgressData,
  trackProgressData,
} from '../pages/[course-type]/learn/[course-name]/utils';
import { setCourseData } from './learnTabSlice';

export const progressEventMapping = {
  coding_problem_solved: 'codingProblemProgress',
  track_progress: 'trackProgress',
  course_progress: 'courseProgress',
  module_progress: 'moduleProgress',
  project_evaluated: 'projectEvaluated',
  lesson_status: 'lessonStatus',
};

const initialState = {
  moduleProgress: {},
  courseProgress: {},
  trackProgress: {},
  codingProblemProgress: {},
  projectEvaluated: {},
  lessonStatus: {},
};

const webscoketSlice = createSlice({
  name: 'webSocketReducer',
  initialState,
  reducers: {
    updateProgress: (state, action) => {
      const { eventType, eventData } = action.payload;
      state[eventType] = eventData;
    },
    updateModuleProgress: (state, action) => {
      state.moduleProgress = action.payload;
    },
    updateCourseProgress: (state, action) => {
      state.courseProgress = action.payload;
    },
    updateTrackProgress: (state, action) => {
      state.trackProgress = action.payload;
    },
    updateCodingProblemProgress: (state, action) => {
      state.codingProblemProgress = action.payload;
    },
    updateLessonCompleted: (state, action) => {
      state.lessonCompleted = action.payload;
    },
  },
});

export const getProgressUpdatesFromWebSocket = (message) => {
  return async (dispatch, getState) => {
    try {
      const courseData = getState().learnTabReducer.courseData ?? {};
      const eventType = progressEventMapping?.[message.eventType] ?? null;
      if (eventType) {
        dispatch(updateProgress({ eventType, eventData: message.eventData }));
      }
      if (eventType === 'trackProgress') {
        const trackData = trackProgressData(courseData, message.eventData);
        await dispatch(setCourseData(trackData));
      }
      if (eventType === 'moduleProgress') {
        const moduleData = moduleProgressData(courseData, message.eventData);
        await dispatch(setCourseData(moduleData));
      }
      if (eventType === 'courseProgress') {
        const course = courseProgressData(courseData, message.eventData);
        await dispatch(setCourseData(course));
      }
      if (eventType === 'projectEvaluated') {
        const projectData = projectProgressData(courseData, message.eventData);
        await dispatch(setCourseData(projectData));
      }
      if (eventType === 'lessonStatus') {
        const lessonComplete = lessonCompletedData(courseData, message.eventData);
        await dispatch(setCourseData(lessonComplete));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const {
  updateProgress,
  updateModuleProgress,
  updateTrackProgress,
  updateCourseProgress,
  updateCodingProblemProgress,
  updateLessonCompleted,
} = webscoketSlice.actions;

export default webscoketSlice.reducer;
