import mixpanel from 'mixpanel-browser';
import { getFullName } from '../utils';

export function identifyUser(user) {
  mixpanel.identify(user.id);
  mixpanel.people.set({
    $name: getFullName(user),
    $email: user.email,
  });
}

export function trackPageView({ page }) {
  mixpanel.track_pageview({ page });
}

export function resetUser() {
  mixpanel.reset();
}
