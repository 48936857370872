import { COURSE_TYPE, PLAN_TYPE } from 'src/constants';

export function getCourse(course) {
  let selectedCourse = '';
  switch (course) {
    case 'web3':
    case COURSE_TYPE.WEB_DEV:
    case COURSE_TYPE.MASTERS_CS:
      selectedCourse = 'full-stack-web-development-with-web3';
      break;
    case COURSE_TYPE.DATA_SCIENCE:
    case COURSE_TYPE.MASTERS_DS_AS:
      selectedCourse = 'full-stack-data-science';
      break;
    case COURSE_TYPE.DATA_ENGINEERING:
      selectedCourse = 'full-stack-data-engineering';
      break;
    case COURSE_TYPE.DS_BOOTCAMP:
      selectedCourse = 'dsBootcamp';
      break;
    case 'ugDiplomaCS':
    case COURSE_TYPE.UG_DIPLOMA_CS:
      selectedCourse = 'ugDiplomaCS';
      break;
    default:
      selectedCourse = 'full-stack-data-science';
      break;
  }
  return selectedCourse;
}

export function getCourseForRouting(course) {
  let selectedCourse = '';
  switch (course) {
    case COURSE_TYPE.WEB_DEV:
      selectedCourse = 'full-stack-web-development';
      break;
    case COURSE_TYPE.DATA_SCIENCE:
      selectedCourse = 'full-stack-data-science';
      break;
    case COURSE_TYPE.DATA_ENGINEERING:
      selectedCourse = 'full-stack-data-engineering';
      break;
    case COURSE_TYPE.DS_BOOTCAMP:
      selectedCourse = 'dsBootcamp';
      break;
    case COURSE_TYPE.MASTERS_DS_AS:
      selectedCourse = 'masters-in-data-science';
      break;
    case COURSE_TYPE.MASTERS_CS:
      selectedCourse = 'masters-in-computer-science';
      break;
    case COURSE_TYPE.UG_DIPLOMA_CS:
      selectedCourse = 'ug-diploma-in-computer-science';
      break;
    default:
      selectedCourse = 'full-stack-data-science';
      break;
  }
  return selectedCourse;
}

export function returnCourseNameAccToCourseAndPlan(course, plan) {
  if (course === COURSE_TYPE.WEB3 && plan === PLAN_TYPE.PRO_LIVE) {
    return 'Certification in Full Stack Web Development';
  } else if (course === COURSE_TYPE.DATA_SCIENCE && plan === PLAN_TYPE.PRO_LIVE) {
    return 'Certification in Full Stack Data Science and AI';
  } else if (course === COURSE_TYPE.WEB3 && plan === PLAN_TYPE.MASTER) {
    return 'Masters in Computer Science : Software Engineering';
  } else if (course === COURSE_TYPE.DATA_SCIENCE && plan === PLAN_TYPE.MASTER) {
    return 'Masters in CS : Data Science and Artificial Intelligence';
  } else {
    return 'Certification in Full Stack Data Science and AI';
  }
}

export function returnCourseName(course) {
  let selectedCourse = '';
  switch (course) {
    case COURSE_TYPE.WEB_DEV:
      selectedCourse = 'Certification in Full Stack Web Development';
      break;
    case COURSE_TYPE.DATA_SCIENCE:
      selectedCourse = 'Certification in Full Stack Data Science and AI';
      break;
    case COURSE_TYPE.DATA_ENGINEERING:
      selectedCourse = 'Full Stack Data Engineering';
      break;
    case COURSE_TYPE.DS_BOOTCAMP:
      selectedCourse = 'Ds Bootcamp';
      break;
    case COURSE_TYPE.MASTERS_DS_AS:
      selectedCourse = 'Masters in CS : Data Science and Artificial Intelligence';
      break;
    case COURSE_TYPE.MASTERS_CS:
      selectedCourse = 'Masters in Computer Science : Cloud Computing';
      break;
    case COURSE_TYPE.UG_DIPLOMA_CS:
      selectedCourse = 'UG Diploma in Computer Science';
      break;
    default:
      selectedCourse = 'Certification in Full Stack Data Science and AI';
      break;
  }
  return selectedCourse;
}

export function returnCourseCamelCaseName(course) {
  switch (course) {
    case COURSE_TYPE.WEB_DEV:
      return 'web3';
    case COURSE_TYPE.DATA_SCIENCE:
      return 'dataScience';
    case COURSE_TYPE.DS_BOOTCAMP:
      return 'dsBootcamp';
    case COURSE_TYPE.DATA_ENGINEERING:
      return 'dataEngineering';
    case COURSE_TYPE.MASTERS_DS_AS:
      return 'mastersDs';
    case COURSE_TYPE.MASTERS_CS:
      return 'mastersCS';
    case 'ugDiplomaCS':
    case COURSE_TYPE.UG_DIPLOMA_CS:
      return 'ugDiplomaCS';
    case 'web3':
      return 'web3';
    default:
      return 'dataScience';
  }
}

export function returnCourseApiName(course) {
  if (course === COURSE_TYPE.WEB_DEV) {
    return 'web3';
  }
  return course;
}

export function returnCourseNameFromCamelCase(course) {
  switch (course) {
    case 'web3':
      return 'Certification in Full Stack Web Development';
    case 'dataScience':
      return 'Certification in Full Stack Data Science and AI';
    case 'dsBootcamp':
      return 'Ds Bootcamp';
    case 'dataEngineering':
      return 'Full Stack Data Engineering';
    case 'mastersDs':
      return 'Masters in CS : Data Science and Artificial Intelligence';
    case 'mastersCS':
      return 'Masters in CS : Data Science and Artificial Intelligence';
    case 'ugDiplomaCS':
      return 'UG Diploma in Computer Science';
    default:
      return 'Certification in Full Stack Data Science and AI';
  }
}

export function returnActualCourseNameForApi(course) {
  switch (course) {
    case COURSE_TYPE.MASTERS_DS_AS:
      return COURSE_TYPE.DATA_SCIENCE;
    case COURSE_TYPE.MASTERS_CS:
    case COURSE_TYPE.WEB_DEV:
      return COURSE_TYPE.WEB3;
    default:
      return course;
  }
}

export function returnRespectiveMastersCourse(course) {
  switch (course) {
    case COURSE_TYPE.DATA_SCIENCE:
      return COURSE_TYPE.MASTERS_DS_AS;
    case COURSE_TYPE.WEB_DEV:
    case COURSE_TYPE.WEB3:
      return COURSE_TYPE.MASTERS_CS;
    default:
      return course;
  }
}
