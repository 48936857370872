import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Provider } from 'react-redux';
import store from './redux/store';
import { MyErrorBoundary } from './ErrorBoundary';
import './static/css/index.css';
import LinkedInTag from 'react-linkedin-insight';
import 'src/plugins/dayjs';
import mixpanel from 'mixpanel-browser';

if (process.env.NODE_ENV !== 'development') {
  LinkedInTag.init('2558316', 'px', false);
}
mixpanel.init(
  process.env.NODE_ENV !== 'development' ? '99b136e107efb941c6b779aa2f034804' : 'dummy-token',
  {
    debug: process.env.REACT_APP_ENV === 'dev',
    persistence: 'localStorage',
    api_host: 'https://phaseshift.almagrow.com',
    api_payload_format: 'json',
  }
);

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <MyErrorBoundary>
      <App />
    </MyErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
