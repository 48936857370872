import { API } from 'aws-amplify';
import {
  LEADSQUARED_API_GATEWAY_ENDPOINT_NAME,
  LEARN_SUBMISSION_API_ENDPOINT_NAME,
  USER_API_GATEWAY_ENDPOINT_NAME,
} from '../aws-exports';
import { getCourse } from './getcourse';
import { LOCAL_STORAGE_KEYS } from '../constants';

export const getUpcomingClasses = async (cohort) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/v2/classroom/upcoming-class-for-cohort?cohortName=${cohort}`
  );
};

export const getMentors = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/strapi/mentors?courseType=${course}`
  );
};

export const registerEvent = async ({ eventId, eventType }) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/event/register-user`, {
    body: {
      eventId,
      eventType,
    },
  });
};

export const leadSquareForEventRegistration = async (details) => {
  return await API.post(LEADSQUARED_API_GATEWAY_ENDPOINT_NAME, `/api/leadsquared-event-capture`, {
    body: details,
  });
};

export const getEventById = async ({ eventId, eventType }) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/event/single-event?eventId=${eventId}&eventType=${eventType}`
  );
};

export const getEventBookings = async (event) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/event/booking/get-booking-status/${event}`
  );
};

export const getStories = async (event) => {
  let courseName = getCourse(event);

  if (
    courseName === 'masters-in-data-science' ||
    courseName === 'masters-in-computer-science' ||
    courseName === 'ug-diploma-in-computer-science'
  ) {
    courseName = 'full-stack-data-science';
  }

  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/success-stories/${courseName}`
  );
};

export const getAllEvents = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/event/upcoming-events?courseType=${course}`
  );
};

// community
export const getCommunityUpdates = async () => {
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/community/updates`);
};

export const getCommunityUpdatesForEnrolledUsers = async (courseName) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/community/updates-for-enrolled-user?courseType=${courseName}`
  );
};

export const getGlobalSettings = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/global-settings/v2');
};
let globalSettingsPromise = null;

export const getCachedGlobalSettings = async () => {
  if (globalSettingsPromise) {
    return globalSettingsPromise;
  } else {
    globalSettingsPromise = (async () => {
      let globalSettings = await getGlobalSettings();
      return globalSettings;
    })();
    return globalSettingsPromise;
  }
};

export const getUpcomingDemoClass = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-demo-class/upcoming`);
};

export const registerDemoClass = async (payload = {}) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-demo-class/register`, {
    body: payload,
  });
};

export const getMastersCourseData = async (slug) => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/masters-application/course-data', {
    queryStringParameters: {
      slug,
    },
  });
};
