import React, { useEffect } from 'react';
import {
  appendToExploredCourses,
  markCoursesAsInterest,
  updateCurrentCourse,
} from '../../../services/userApiService';
import {
  COURSE_INTEREST_COURSE_TYPE,
  getGoalAndPlanBasedOnInterest,
  LOCAL_STORAGE_KEYS,
} from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, loggedInUserSelector } from '../../../redux/authSlice';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { MODULES_PATH } from '../../../route-constants';
import LinkedInTag from 'react-linkedin-insight';
import { setUserSelectedCourses } from 'src/redux/courseSlice';

export const loadSignInScript = async ({ id: userId, email, createdOn, ...otherDetails }) => {
  if (!email || !createdOn) return;
  const isNewlySignedIn = dayjs(new Date()).diff(createdOn, 'minute') < 5;
  const user = await Auth.currentAuthenticatedUser();
  const isGoogleUser = user?.username?.startsWith('Google');
  const userData = {
    email: email,
  };
  if (otherDetails.phone) {
    userData.phone_number = otherDetails.phone;
  }
  window.gtag && window.gtag('set', 'user_data', userData);

  window.gtag &&
    window.gtag('event', 'conversion', {
      send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
      transaction_id: userId,
    });
  if (isNewlySignedIn) {
    // 0 = current minute, 1 = one more minute
    const scriptSetting = document.createElement('script');
    scriptSetting.innerText = `dataLayer.push({
           'event': 'form_submit',
           'enhanced_conversion_data': {
             "email": '${email}',
           },
     });
  `;
    window.fbq && window.fbq('track', 'CompleteRegistration', { eventID: userId });
    LinkedInTag.track('12759940', '2558316');
    document.body.appendChild(scriptSetting);
    window?.snaptr?.('track', 'SIGN_UP');
    window?.scq?.('Complete registration', 'pre_defined');
    window?.qp?.('track', 'CompleteRegistration');
  }
};

function LoginSuccessful() {
  const dispatch = useDispatch();
  const userDetails = useSelector(loggedInUserSelector);
  const history = useHistory();

  const returnGoalAndPlan = (courseInterest) => {
    return getGoalAndPlanBasedOnInterest[courseInterest] ?? {};
  };

  const sendUserInterest = async () => {
    let courseInterests = localStorage.getItem(LOCAL_STORAGE_KEYS.COURSE_INTERESTS);
    if (!courseInterests) return;
    courseInterests = JSON.parse(courseInterests);
    if (courseInterests.length) {
      const goalAndPlanObject = returnGoalAndPlan(COURSE_INTEREST_COURSE_TYPE[courseInterests[0]]);
      try {
        const apiData = {
          goal: {
            goalType: goalAndPlanObject?.goalType,
            planType: goalAndPlanObject?.planType,
          },
        };
        const result = await appendToExploredCourses(apiData);
        if (result) {
          const res = await updateCurrentCourse(apiData);
          res && dispatch(setUserSelectedCourses(res));
        }
      } catch (err) {
        console.error(err);
      }
    }
    localStorage.removeItem(LOCAL_STORAGE_KEYS.COURSE_INTERESTS);
    await markCoursesAsInterest(courseInterests);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        if (!userDetails) dispatch(loadUser());
      })
      .catch((err) => {
        console.error(err);
        history.replace(MODULES_PATH.AUTH.LOGIN);
      });
  }, []);

  useEffect(() => {
    const onUserDetailsAvailable = async () => {
      await sendUserInterest();
      await loadSignInScript(userDetails);
      history.push('/onboarding');
    };
    if (userDetails) {
      onUserDetailsAvailable();
    }
  }, [userDetails]);

  if (!userDetails) return <div />;

  return <div />;
}

export default LoginSuccessful;
