import { Auth } from 'aws-amplify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MODULES_PATH } from 'src/route-constants';
import { LOCAL_STORAGE_KEYS } from '../../../constants';

function Logout() {
  const history = useHistory();

  React.useEffect(() => {
    async function logOut() {
      try {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.GLOBAL_SETTINGS);
        await Auth.signOut();
        history.replace(MODULES_PATH.AUTH.LOGIN);
      } catch (e) {
        console.error(e);
      }
    }
    logOut();
  }, []);

  return <></>;
}

export default Logout;
