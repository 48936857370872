import { TextField, Checkbox, withStyles, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { COURSE_TYPE, PLAN_TYPE } from './constants';

export const isUrlValid = (url) => {
  const res = url.match(
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

export const getFullName = (user) => {
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  return `${firstName} ${lastName}`.trim();
};

export const getFirstAndLastNamesFromName = (name) => {
  if (!name) {
    return { firstName: null, lastName: null };
  }
  const matches = name.match(/\S+/g);
  const firstName = matches[0];
  const lastName = matches.slice(1).join(' ');
  return { firstName, lastName };
};

export class UserDefinedError extends Error {}

export const isEmailNotVerified = (user) => {
  return user.isEmailNotVerified;
};
export const doesUserHaveMissingDetails = (user) => {
  return !user.firstName || !user.lastName || !user.phone;
};

export const doesUserHaveMissingPhone = (user) => {
  return !user?.phone;
};

export function isKeyTrue(obj, key) {
  if (!obj) return false;
  try {
    return Object.hasOwnProperty.call(obj, key) && obj[key] === true;
  } catch (e) {
    return false;
  }
}

export function isKeyFalse(obj, key) {
  if (!obj) return false;
  try {
    return Object.hasOwnProperty.call(obj, key) && obj[key] === false;
  } catch (e) {
    return false;
  }
}

export const useDarkThemeInputStyle = makeStyles((theme) => ({
  root: {
    '& label': {
      color: theme.palette.inputField.default,
    },
    '& label.Mui-disabled': {
      color: theme.palette.inputField.disabled,
    },
    '& label.Mui-focused': {
      color: theme.palette.inputField.focused,
    },
    '& input': {
      color: theme.palette.inputField.focused,
    },
    '& input.Mui-disabled': {
      color: theme.palette.inputField.disabled,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.inputField.default,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.inputField.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.inputField.focused,
      },
      '&.Mui-disabled fieldset': {
        borderColor: theme.palette.inputField.disabled,
      },
      '& .MuiSelect-select': {
        color: theme.palette.inputField.focused,
      },
    },
  },
  icon: {
    fill: theme.palette.inputField.default,
  },
  button: {
    '&:hover': {
      transition: 'transform 0.3s',
      transform: 'scale(1.05)',
    },
    transition: 'transform 0.25s',
    fontSize: 'inherit',
  },
}));

export const TextFieldDark = withStyles((theme) => {
  return {
    root: {
      '& label': {
        color: theme.palette.inputField.default,
      },
      '& label.Mui-disabled': {
        color: theme.palette.inputField.disabled,
      },
      '& label.Mui-focused': {
        color: theme.palette.inputField.focused,
      },
      '& textarea': {
        color: theme.palette.inputField.focused,
      },
      '& input': {
        color: theme.palette.inputField.focused,
      },
      '& input.Mui-disabled': {
        color: theme.palette.inputField.disabled,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.inputField.default,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.inputField.hover,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.inputField.focused,
        },
        '&.Mui-disabled fieldset': {
          borderColor: theme.palette.inputField.disabled,
        },
      },
    },
  };
})(TextField);

export const CheckboxDark = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.checkbox.checked,
    },
    color: theme.palette.checkbox.default,
  },
  checked: {},
}))(Checkbox);

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateFullname = (name) => {
  const fullName = name?.replace(/\s+/g, ' ').trim();
  const fullNameRegex = /^[a-zA-Z]+\s[a-zA-Z]+(\s[a-zA-Z]+)*$/;
  return fullNameRegex.test(fullName);
};

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16)?.length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16)?.length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16)?.length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

export const formatBytes = (bytes, decimals = 2) => {
  const d = Math.floor(Math.log(bytes) / Math.log(1024));
  return 0 === bytes
    ? '0 Bytes'
    : parseFloat((bytes / Math.pow(1024, d)).toFixed(Math.max(0, decimals))) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB'][d];
};

export const isWeekEndDay = (date) => [0, 6].includes(dayjs(date).weekday());

export const readUtcTimeAsLocalTime = (utcTime) => {
  if (!utcTime) return;
  const localTime = utcTime.slice(0, -1) + '+05:30';
  return localTime;
};

export function checkTimeNearClasses() {
  // Convert time strings to Date objects
  const startTime = new Date(`1970-01-01T19:30:00`);
  const endTime = new Date(`1970-01-01T10:00:00`);

  // Get the current time
  const currentDateTime = new Date();
  const currentTimeStr = currentDateTime.toLocaleTimeString('en-US', { hour12: false });
  const checkTime = new Date(`1970-01-01T${currentTimeStr}`);

  // Check if the check_time falls within the specified range
  if (startTime <= endTime) {
    return startTime <= checkTime && checkTime <= endTime;
  } else {
    return startTime <= checkTime || checkTime <= endTime;
  }
}

export const numberFormatter = (num) => {
  const formatter = new Intl.NumberFormat('en-IN', {
    currency: 'INR',
  });
  return formatter.format(num);
};

export const tagColor = {
  pending: {
    color: '#EC7622',
    backgroundColor: 'rgba(255, 122, 0, 0.08)',
  },
  success: {
    color: '#4C9D8A',
    backgroundColor: 'rgba(32, 168, 152, 0.08)',
  },
  error: {
    color: '#F00037',
    backgroundColor: 'rgba(240, 0, 55, 0.08)',
  },
};

export function getRedirectTo() {
  const urlSearchText = window.location.search;
  if (urlSearchText) {
    const urlSearchParams = new URLSearchParams(urlSearchText);

    const redirectTo = urlSearchParams.get('redirectTo');

    if (redirectTo) return redirectTo;
  }
}

export const getImgUrl = (payload, preferred = 'medium') => {
  const formats = payload?.data?.attributes?.formats || payload?.formats || payload;
  if (formats[preferred]?.url) {
    return formats[preferred].url;
  }
  return (
    formats?.medium?.url ||
    formats?.large?.url ||
    payload?.data?.attributes?.url ||
    formats?.url ||
    payload?.url
  );
};

export function isValidWebSocketURL(url) {
  try {
    const websocketUrlPattern = /^(wss?|ws):\/\/\S+$/;
    return websocketUrlPattern.test(url);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const masterEnrol = {
  pathname: (course) => `/${course}/home`,
  mastersCourseSelected: (course) =>
    [COURSE_TYPE.MASTERS_CS, COURSE_TYPE.MASTERS_DS_AS, COURSE_TYPE.UG_DIPLOMA_CS]?.includes(
      course
    ),
};

export const currentCourse = {
  [PLAN_TYPE.MASTER]: {
    [COURSE_TYPE.DATA_SCIENCE]: COURSE_TYPE.DATA_SCIENCE,
    [COURSE_TYPE.WEB_DEV]: COURSE_TYPE.WEB_DEV,
    [COURSE_TYPE.WEB3]: COURSE_TYPE.WEB_DEV,
  },
  [PLAN_TYPE.PRO_LIVE]: {
    [COURSE_TYPE.DATA_SCIENCE]: COURSE_TYPE.DATA_SCIENCE,
    [COURSE_TYPE.WEB_DEV]: COURSE_TYPE.WEB_DEV,
    [COURSE_TYPE.UG_DIPLOMA_CS]: COURSE_TYPE.UG_DIPLOMA_CS,
    [COURSE_TYPE.WEB3]: COURSE_TYPE.WEB_DEV,
  },
};

export const getRespectiveMastersCourse = (course) => {
  if (course === COURSE_TYPE.DATA_SCIENCE) return COURSE_TYPE.MASTERS_DS_AS;
  if (course === COURSE_TYPE.WEB_DEV) return COURSE_TYPE.MASTERS_CS;
  return course;
};

export function isZeroOrGreater(value) {
  if (typeof value === 'number' && value >= 0) {
    return true;
  }
  if (typeof value === 'string' && !isNaN(value) && parseFloat(value) >= 0) {
    return true;
  }
  return false;
}

export const scrollToTop = (id) => {
  const element = document.getElementById(id);
  if (!element || !element.scrollTo) return;
  element.scrollTo(0, 0);
};

export function DateTimeFormatter(date = '', format = '', calendar = false) {
  return calendar
    ? dayjs(date).calendar(null, {
        sameDay: `${format} [Today]`,
        nextDay: `${format} [Tomorrow]`,
        nextWeek: `${format} ddd Do`,
        lastDay: `${format} [Yesterday]`,
        sameElse: `${format} Do MMM, YYYY`,
      })
    : dayjs(date).format(format || 'DD MMM, YYYY');
}

export function captureParams() {
  const queryString = window.location.search;

  if (!queryString) return;

  const urlSearchParams = new URLSearchParams(queryString);

  const newQueryString = Array.from(urlSearchParams.entries()).reduce((accu, [key, value]) => {
    if (key !== 'redirectTo') {
      if (!accu) {
        accu += `?${key}=${value}`;
      } else {
        accu += `&${key}=${value}`;
      }
    }

    return accu;
  }, '');

  return newQueryString;
}
