export const MODULES_PATH = {
  CHAT: '/chat',
  ONBOARDING: '/onboarding',
  JOB_BOARD: {
    HOME: '/job-board',
    SINGLE_JOB_VIEW: '/job-board/:id',
  },
  REGISTER_EVENT: '/register-event',
  MY_ACCOUNT: {
    HOME: '/my-account',
    CERTIFICATES: '/my-certificates',
    SHARE_LINKEDIN_POST: '/share-linkedin-post',
    get PROGRAM_INFORMATION() {
      return `${this.HOME}/program-information`;
    },
    get PASSWORD() {
      return `${this.HOME}/password`;
    },
    get EDUCATION() {
      return `${this.HOME}/education`;
    },
    get WORK_EXPERIENCE() {
      return `${this.HOME}/work-experience`;
    },
    get SKILLS() {
      return `${this.HOME}/skills`;
    },
  },
  PAYMENT: {
    HOME: (course) => `/${course}/payment`,
    SUCCESS: (course) => `/${course}/payment/success`,
    POST_PAYMENT_ONBOARDING: '/payment/onboarding',
  },
  MASTERS: {
    ONBOARDING: (course) => `/${course}/onboarding`,
    EURO: (course) => `/${course}/euro-scholarship`,
    ANNOUNCEMENT: (course) => `/${course}/announcement`,
  },
  AUTH: {
    HOME: '/auth',
    get LOGIN() {
      return `${this.HOME}/login`;
    },
    get FRESHDESK_SSO_LOGIN() {
      return `${this.HOME}/sso_login/freshdesk`;
    },
    get THINKIFIC_SSO_LOGIN() {
      return `${this.HOME}/sso_login/thinkific`;
    },
    get SIGN_UP() {
      return `${this.HOME}/signup`;
    },
    get FORGOT_YOUR_PASSWORD() {
      return `${this.HOME}/forgot_your_password`;
    },
    get LOGIN_SUCCESSFUL() {
      return `${this.HOME}/login_successful`;
    },
    get LOGOUT() {
      return `${this.HOME}/logout`;
    },
    get LOGOUT_SUCCESSFUL() {
      return `${this.HOME}/logout_successful`;
    },
    get DISCORD() {
      return `${this.HOME}/discord`;
    },
  },
  COMMUNITY_LANDING: '/community',
  SCHOOL: {
    HOME: (course) => `/${course}/school`,
    INTERMEDIATE_PAGE: (course) => `/${course}/school/join`,
    LIVE_CLASS: (course) => `/${course}/school/live-class`,
  },
  PERFORMANCE_DASHBOARD: (course) => `/${course}/performance-dashboard`,
  COHORT_LEADERBOARD: (course) => `/${course}/cohort-leaderboard`,
  NEW_MENTORSHIP: {
    BASE: (course) => `/${course}/polaris`,
    HOME: (course) => `/${course}/polaris/home`,
    NEW_BOOKING: (course) => `/${course}/polaris/home/book`,
    MANAGE_BOOKINGS: (course) => `/${course}/polaris/manage`,
    INTERVIEW_SIMULATOR: (course) => `/${course}/polaris/session`,
    FEEDBACK: (course) => `/${course}/polaris/manage/feedback`,
    FEEDBACK_ROUTE: (course) => `/${course}/polaris/manage/feedback/:bookingId`,
    REPORT: (course) => `/${course}/polaris/manage/report`,
    REPORT_ROUTE: (course) => `/${course}/polaris/manage/report/:bookingId`,
    WATCH_RECORDINGS: (course) => `/${course}/polaris/manage/recording`,
    WATCH_RECORDINGS_ROUTE: (course) => `/${course}/polaris/manage/recording/:bookingId`,
    LEGACY: (course) => `/${course}/polaris/legacy`,
  },
  GROW_HOME: (course) => `/${course}/home`,
  REFER_AND_EARN: {
    PREFIX: '/refer-and-earn',
    HOME: '/refer-and-earn/dashboard',
    MY_REFEERALS: '/refer-and-earn/my-referrals',
    MY_REWARDS: '/refer-and-earn/my-rewards',
    HISTORY: '/refer-and-earn/history',
  },
  CLASSROOM: {
    HOME: (course) => `/${course}/classroom`,
  },
  COURSE_LMS_DASHBOARD: {
    LMS_IIT: (course) => `/${course}/iit-guwahati`,
    LEARN_HOME: (course) => `/${course}/home`,
    LEARN: (course) => `/${course}/learn`,
    LEARN_PROJECTS: {
      HOME: (course) => `/${course}/projects`,
      SINGLE_PROJECT_VIEW: (course) => `/${course}/projects/:name`,
    },
    LEARN_CAREER: (course) => `/${course}/almax-career-track`,
    LEARN_CAREER_LEARN_TAB: (course) => `/${course}/AlmaX/:name/:type`,
    LEARN_MAIN_COURSES: (course) => `/${course}/learn/:name`,
    LEARN_TRACK_PROJECTS: (course) => `/${course}/learn/:name/:track/:moduleId/capstone-projects`,
    LEARN_TRACK_SINGLE_PROJECT: (course) =>
      `/${course}/learn/:name/:track/:moduleId/capstone-projects/:id`,
    LEARN_MAIN_COURSES_LESSON: (course) => `/${course}/learn/:name/:track/:lesson/:type`,
  },
  PRACTICE_TAB: {
    HOME: (course) => `/${course}/practice`,
    CODING_ASSESSMENT: {
      HOME: (course) => `/${course}/practice/coding-assessments`,
      SINGLE_CODING_ASSESSMENT_VIEW: (course) => `/${course}/practice/coding-assessments/:name`,
    },
    CODING_PROBLEM: {
      HOME: (course) => `/${course}/practice/coding-problems`,
      SINGLE_CODING_PROBLEM: (course) => `/${course}/practice/coding-problems/:name`,
    },
    QUIZ_ASSESSMENT: {
      HOME: (course) => `/${course}/practice/quiz-assessments`,
      SINGLE_ASSESSMENT_VIEW: (course) => `/${course}/practice/quiz-assessments/:name`,
    },
    FREE_COURSE_CARDS: (course) => `/${course}/practice/supplementary-courses`,
    SINGLE_FREE_COURSE: (course) => `/${course}/practice/supplementary-courses/:name`,
    FREE_COURSE_LESSON: (course) =>
      `/${course}/practice/supplementary-courses/:name/:lessonName/:type`,
  },
};
